* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


body {
  scrollbar-color: #888 #fff;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #fff; 
}

body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
  border: 3px solid #ccc;
}